import { PageProps, graphql } from 'gatsby'
import PageSEO from '../components/seo'
import { IStrapiBlogPost } from '../types/strapi'
import PageLayout from '../layouts/page-layout/PageLayout'
import { IMainFormSectionData } from '../components/page-sections/main-form-section'

interface IStrapiBlogResponse {
  strapiBlogPost: IStrapiBlogPost
}

interface IStrapiBlogContext {
  mainForm: IMainFormSectionData
}

type BlogPageProps = PageProps<IStrapiBlogResponse>

export const Head = ({ data, location }: BlogPageProps) => {
  const { seo } = data.strapiBlogPost

  return (
    <PageSEO
      title={seo?.title}
      description={seo?.description}
      pathname={location.pathname}
    />
  )
}

const BlogPage = (props: PageProps<IStrapiBlogResponse, IStrapiBlogContext>) => {
  const { title, content, image, author, categories, date } = props.data.strapiBlogPost

  const { mainForm } = props.pageContext

  return (
    <PageLayout mainForm={mainForm}>
      BLOG POST
    </PageLayout>
  )
}

export default BlogPage

export const query = graphql`
  query($id: String!) {
    strapiBlogPost(id: {eq: $id}) {
      title
      date
      slug
      content {
        data {
          childMarkdownRemark {
            ...StrapiMarkdown
          }
        }
      }
      image {
        ...StrapiMedia
      }
      author
      categories {
        ...StrapiBlogCategory
      }
    }
  }
`
