import { IStrapiImage, IStrapiSeo } from '../../types/strapi'
import useSEO from './useSEO'
import PageElements from '../page-elements'
import { getImageSrc } from '../../utils/getImageSrc'

export interface IPageSEOProps extends IStrapiSeo {
  pathname: string
  preloadImage?: IStrapiImage
}

const PageSEO = ({ title, description, pathname, preloadImage }: IPageSEOProps) => {
  const { siteMetadata, buildTime } = useSEO()

  const pageTitle = title || siteMetadata.title
  const pageDescription = description || siteMetadata.description
  const pageUrl = siteMetadata.siteUrl + pathname

  const preloadImageSrc = getImageSrc(preloadImage)

  return (
    <>
      <PageElements />

      {pageTitle && (
        <title>{pageTitle}</title>
      )}

      {pageDescription && (
        <meta name='description' content={pageDescription} />
      )}

      {buildTime && (
        <meta property='article:modified_time' content={buildTime} />
      )}

      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='article' />
      <meta property='og:title' content={pageTitle} />
      <meta property='og:description' content={pageDescription} />
      <meta property='og:url' content={pageUrl} />
      <meta property='og:site_name' content='Simtech Development' />

      <meta property='og:image' content='https://simtechdev.com/images/sd-og-default-image.avif' />
      <meta property='og:image:width' content='1115' />
      <meta property='og:image:height' content='627' />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@simtechdev' />

      {preloadImageSrc && (
        <link rel='preload' as='image' href={preloadImageSrc} />
      )}
    </>
  )
}

export default PageSEO
